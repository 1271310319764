@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(https://use.fontawesome.com/releases/v5.7.1/css/all.css);
@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lora&display=swap);
@import url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/199011/bebas.css);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    background-color: #22272c;
}

h1 {
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

li {
    color: #031019;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}

.site-wrap {
    overflow-x: hidden;
    position: relative;
}

.accordion-container {
    width: 65%;
    min-width: 450px;
    max-width: 980px;
    margin: 40px auto;
}

.duties>ul>li {
    font-size: 18px;
    margin-top: 5px;
}

@media only screen and (max-width: 420px) {
    .accordion-container {
        width: 95%;
        min-width: 0px;
        margin: 40px auto;
    }
    h1 {
        font-size: 25px;
    }
    .duties>ul>li {
        font-size: 15px;
        margin-top: 5px;
    }
}
.typewrite, .typewrite-change {
    height: 160px;
    overflow: hidden;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-size: 60px;
    line-height: 60px;
    color: #ffffff;
    white-space: nowrap;
  }

.typewrite-change {
  white-space: nowrap;
}

@media only screen and (max-width: 420px) {
  .typewrite, .typewrite-change {
    font-size: 30px;
  }
}
.letter {
    display: inline-block;
    position: relative;
    -webkit-transform: translateZ(25px);
            transform: translateZ(25px);
    -webkit-transform-origin: 50% 50% 25px;
            transform-origin: 50% 50% 25px;
}
  
.letter.out {
  -webkit-transform: rotateX(90deg);
          transform: rotateX(90deg);
  transition: -webkit-transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
  
.letter.behind {
  -webkit-transform: rotateX(-90deg);
          transform: rotateX(-90deg);
}
  
.letter.in {
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
  transition: -webkit-transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275), -webkit-transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
  
.decl {
  color: #e2e2e2;
  font-size: 50px;
  font-weight: 600;
}
  
.descrip {
  position: absolute;
  width: 800px;
  height: 35px;
  font-family: "Fira Mono", monospace;
  font-weight: 600;
}
  
.word {
  position: absolute;
  width: 900px;
  opacity: 0;
  font-size: 50px;
}
  
.fields {
  color: rgb(124, 220, 240);
  display: inline-block;
  vertical-align: top;
  margin: -70px 0 100px 50px;
}

@media only screen and (max-width: 420px) {
  .decl {
    font-size: 30px;
  }
  .word {
    font-size: 30px;
  }
  .fields {
    margin: -40px 0 70px 25px;
  }
}
.navBar {
    display: flex;
    margin-top: -14px;
    white-space: nowrap;
}
.navItem {
    font-family: "Lato", sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: white;
    margin: 0px 5px 0px 5px;
}

@media only screen and (max-width: 420px) {
    .navItem {
        font-size: 15px;
        margin-top: -10px;
    }
}
.icon-bar {
    display: flex;
    margin-top: 10px;
    white-space: nowrap;
}


#canvas {
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.object-animation {
  position: absolute;
  margin-top: -55vh;
  margin-left: 30vw;
}

.typewrite-animation {
  position: absolute;
  margin-top: -75vh;
  margin-left: 25vw;
}

@media only screen and (max-width: 420px) {
  .typewrite-animation {
    margin-left: 4vw;
  }
  .object-animation {
    margin-left: 8vw;
  }
}

@media only screen and (max-width:1000px) and (min-width:420px) {
  .typewrite-animation {
    margin-left: 10vw;
  }
  .object-animation {
    margin-left: 15vw;
  }
}
.icon {
    color: white;
}

.social-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    width: 40px;
    height: 40px;
    text-decoration: none;
}
.social-button__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: 100%;
    background: transparent;
    text-align: center;
}
.social-button i,
.social-button svg {
    position: relative;
    z-index: 1;
    transition: 0.3s;
}
.social-button i {
    font-size: 30px;
}
.social-button svg {
    height: 40%;
    width: 40%;
}
.social-button::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-radius: 100%;
    transition: 0.15s;
}
.social-button:focus,
.social-button:hover {
    color: #fff;
}
.social-button:focus::after,
.social-button:hover::after {
    width: 100%;
    height: 100%;
    margin-left: -50%;
}

.notify {
    font-size: 20px;
}

.social-button--github::after {
    background: #6e5494;
}

.social-button--mail::after {
    background: #993b3b;
}

.social-button--linkedin::after {
    background: #0077b5;
}

.social-button--steam::after {
    background: #1db954;
}

.social-button--instagram::after {
    background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
    );
}

/* .social-button--linkedin {
    color: #0077b5;
}
.social-button--mail {
    color: #993b3b;
}

.social-button--steam {
    color: #1db954;
}
.social-button--instagram {
    color: #3f729b;
} */

.work-logos {
    object-fit: contain;
    max-width: 100px;
    height: 100px;
}

.work-header {
    margin-top: 12px;
    margin-left: 20px;
}

.work-company {
    font-size: 30px;
    font-weight: 500;
    font-family: "Oswald", sans-serif;
    color: white;
}
.work-title {
    font-size: 18px;
}

.work-date {
    position: absolute;
    right: 20px;
    font-size: 16px;
}

.work-date, .work-title {
    color: #031019c7;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.MuiAccordion-root {
    border-radius: 8px !important;
}


.MuiAccordionDetails-root {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

@media only screen and (max-width: 800px) {
    .work-date {
        display: none;
    }
}


@media only screen and (max-width: 420px) {
    .work-company {
        font-size: 20px;
    }
    .work-title {
        font-size: 14px;
    }
}
.tech-stack {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.tech {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    padding: 2px 5px 2px 5px;
    margin: 3px 3px 0 3px;
    border-radius: 5px;
    color: white !important;
    background-color: #8d9ea5;
}

@media only screen and (max-width: 420px) {
    .tech {
        font-size: 13px;
    }
}
#trophy {
    margin: 11px 0 0 5px;
    color: gold;
}

.winner {
    font-weight: 700;
}

.project-header {
    display: flex;
    padding: 8px 0 8px 0;
}

.project-title {
    white-space: nowrap;
    font-size: 23px;
    font-family: "Oswald", sans-serif;
    color: white;
}
.project-summary {
    margin: 9px 0 0 10px;
    font-size: 15px;
    color: #031019c7;
    width: 450px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.project-link-wrapper {
    display: flex;
    position: absolute;
    bottom: 25%;
    right: 50px;
}

.project-link {
    margin: 8px 5px 0 0;
    font-size: 15px;
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}

@media only screen and (max-width: 1260px) {
    .project-summary {
        display: none;
    }
}


@media only screen and (max-width: 420px) {
    .project-title {
        font-size: 18px;
    }
    #trophy {
        margin: 7px 0 0 5px;
        color: gold;
    }
    .project-link {
        margin: 15px 5px 0 0;
        font-size: 12px;
    }
}

@media only screen and (max-width: 350px) {
    .social-button--github, .project-link {
        display: none;
    }
}

.about-me-container {
    width: 65%;
    min-width: 450px;
    max-width: 980px;
    margin: 40px auto;
}

.about-me-content {
    background-color: #597387;
    padding: 10px 0 20px 0;
    border-radius: 10px;
}

.about-me-info {
    width: 90%;
    margin: 0 auto;
    background-color: #d5dfe3;
    padding: 3%;
    border-radius: 10px;
    font-family: "Poppins", sans-serif;
}

.about-me-content-upper {
    width: 98%;
    display: flex;
    margin: 0 auto;
    margin-bottom: 10px;
    clear: right;
}

.image-palette-wrapper {
    display: grid;
    margin: auto;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
}

.paletteImage {
    width: 92%;
    border-radius: 5px;
    margin: 10px 0px 10px 10px;
}

@media only screen and (max-width: 1150px) {
    .image-palette-wrapper {
        display: none
    }
}

@media only screen and (max-width: 420px) {
    .about-me-container {
        width: 95%;
        min-width: 0;
        margin: 40px auto;
    }
    .about-me-info {
        font-size: 15px;
    }
}
.footer {
    text-align: center;
}

.copyright {
    color: lightgrey;
    font-family: "Open Sans", sans-serif;
    font-size: 1.1em;
    margin-bottom: 30px;
    line-height: 1.2;
}

