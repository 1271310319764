.navBar {
    display: flex;
    margin-top: -14px;
    white-space: nowrap;
}
.navItem {
    font-family: "Lato", sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: white;
    margin: 0px 5px 0px 5px;
}

@media only screen and (max-width: 420px) {
    .navItem {
        font-size: 15px;
        margin-top: -10px;
    }
}