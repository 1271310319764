.footer {
    text-align: center;
}

.copyright {
    color: lightgrey;
    font-family: "Open Sans", sans-serif;
    font-size: 1.1em;
    margin-bottom: 30px;
    line-height: 1.2;
}
