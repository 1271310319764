.icon {
    color: white;
}

.social-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    width: 40px;
    height: 40px;
    text-decoration: none;
}
.social-button__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: 100%;
    background: transparent;
    text-align: center;
}
.social-button i,
.social-button svg {
    position: relative;
    z-index: 1;
    transition: 0.3s;
}
.social-button i {
    font-size: 30px;
}
.social-button svg {
    height: 40%;
    width: 40%;
}
.social-button::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    border-radius: 100%;
    transition: 0.15s;
}
.social-button:focus,
.social-button:hover {
    color: #fff;
}
.social-button:focus::after,
.social-button:hover::after {
    width: 100%;
    height: 100%;
    margin-left: -50%;
}

.notify {
    font-size: 20px;
}

.social-button--github::after {
    background: #6e5494;
}

.social-button--mail::after {
    background: #993b3b;
}

.social-button--linkedin::after {
    background: #0077b5;
}

.social-button--steam::after {
    background: #1db954;
}

.social-button--instagram::after {
    background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
    );
}

/* .social-button--linkedin {
    color: #0077b5;
}
.social-button--mail {
    color: #993b3b;
}

.social-button--steam {
    color: #1db954;
}
.social-button--instagram {
    color: #3f729b;
} */
