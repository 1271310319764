
.about-me-container {
    width: 65%;
    min-width: 450px;
    max-width: 980px;
    margin: 40px auto;
}

.about-me-content {
    background-color: #597387;
    padding: 10px 0 20px 0;
    border-radius: 10px;
}

.about-me-info {
    width: 90%;
    margin: 0 auto;
    background-color: #d5dfe3;
    padding: 3%;
    border-radius: 10px;
    font-family: "Poppins", sans-serif;
}

.about-me-content-upper {
    width: 98%;
    display: flex;
    margin: 0 auto;
    margin-bottom: 10px;
    clear: right;
}

.image-palette-wrapper {
    display: grid;
    margin: auto;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
}

.paletteImage {
    width: 92%;
    border-radius: 5px;
    margin: 10px 0px 10px 10px;
}

@media only screen and (max-width: 1150px) {
    .image-palette-wrapper {
        display: none
    }
}

@media only screen and (max-width: 420px) {
    .about-me-container {
        width: 95%;
        min-width: 0;
        margin: 40px auto;
    }
    .about-me-info {
        font-size: 15px;
    }
}