#canvas {
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.object-animation {
  position: absolute;
  margin-top: -55vh;
  margin-left: 30vw;
}

.typewrite-animation {
  position: absolute;
  margin-top: -75vh;
  margin-left: 25vw;
}

@media only screen and (max-width: 420px) {
  .typewrite-animation {
    margin-left: 4vw;
  }
  .object-animation {
    margin-left: 8vw;
  }
}

@media only screen and (max-width:1000px) and (min-width:420px) {
  .typewrite-animation {
    margin-left: 10vw;
  }
  .object-animation {
    margin-left: 15vw;
  }
}