.letter {
    display: inline-block;
    position: relative;
    transform: translateZ(25px);
    transform-origin: 50% 50% 25px;
}
  
.letter.out {
  transform: rotateX(90deg);
  transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
  
.letter.behind {
  transform: rotateX(-90deg);
}
  
.letter.in {
  transform: rotateX(0deg);
  transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
  
.decl {
  color: #e2e2e2;
  font-size: 50px;
  font-weight: 600;
}
  
.descrip {
  position: absolute;
  width: 800px;
  height: 35px;
  font-family: "Fira Mono", monospace;
  font-weight: 600;
}
  
.word {
  position: absolute;
  width: 900px;
  opacity: 0;
  font-size: 50px;
}
  
.fields {
  color: rgb(124, 220, 240);
  display: inline-block;
  vertical-align: top;
  margin: -70px 0 100px 50px;
}

@media only screen and (max-width: 420px) {
  .decl {
    font-size: 30px;
  }
  .word {
    font-size: 30px;
  }
  .fields {
    margin: -40px 0 70px 25px;
  }
}